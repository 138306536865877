export const reCaptchaKey = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.reCaptchaKey
    ? window.manifest.reCaptchaKey
    : '6LcpXd4UAAAAABG7ilvQgQAmobLvrJqn-4VaupNC';

export const gMapApiKey = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.gMapApiKey
    ? window.manifest.gMapApiKey
    : 'AIzaSyAFq1fItucvNN8W751EX2dHcankbZcdFdA';

export const tomtomApiKey = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.tomtomApiKey
    ? window.manifest.tomtomApiKey
    : 'bqrAFvitDbayXNKw0G4ZQZ70IeDHo07w';

export const tenantId = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.tenantId
    ? window.manifest.tenantId
    : 90327182;

export const baseUrl = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com';

export const masterDataUrl = `${baseUrl()}/api/study/codes/all?tenantId=${tenantId()}`;

export const searchTrialUrl = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/search?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const submitFormUrl = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/contact-us/submit?tenantId=' +
  tenantId();

export const allTrialsIds = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/ids/all?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const trialDetailsUrl = (studyId, Id) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/?tenantId=' +
  tenantId() +
  (Id || '&uniqueStudyId=') +
  // '&Id=' +
  studyId +
  '&_cache=' +
  new Date();

export const fileUploadMaxSize = () =>
  ((typeof window !== 'undefined' &&
    window.manifest &&
    window.manifest.upload_max_file_mb) ||
    1) * 1048576; // 1048576 = 1MB
