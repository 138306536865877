exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-advanced-study-search-js": () => import("./../../../src/pages/en/advanced-study-search.js" /* webpackChunkName: "component---src-pages-en-advanced-study-search-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-results-js": () => import("./../../../src/pages/en/results.js" /* webpackChunkName: "component---src-pages-en-results-js" */),
  "component---src-pages-en-sitemap-js": () => import("./../../../src/pages/en/sitemap.js" /* webpackChunkName: "component---src-pages-en-sitemap-js" */),
  "component---src-pages-en-trial-details-js": () => import("./../../../src/pages/en/trial-details.js" /* webpackChunkName: "component---src-pages-en-trial-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

