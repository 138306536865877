import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Transition extends PureComponent {
  render() {
    // const { children, location } = this.props;
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Transition;
