import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { masterDataUrl } from '../constants/config';
const MasterDataContext = createContext();
class MasterDataProvider extends Component {
  state = {
    masterData: null,
  };
  componentDidMount() {
    this.getMasterData();
  }
  async getMasterData() {
    try {
      const response = await fetch(masterDataUrl);
      const data = await response.json();
      this.setState({ masterData: data.Data });
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    return (
      <MasterDataContext.Provider value={this.state}>
        {this.props.children}
      </MasterDataContext.Provider>
    );
  }
}
MasterDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { MasterDataProvider, MasterDataContext };
